/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql, navigate } from 'gatsby'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { H1 } from '@components/Heading'
import { Text } from '@components/Text'
import { Button } from '@components/Button'
import { Box } from '@components/Grid'

const NotFoundPage = ({ data: { content } }) => (
  <Layout>
    <SEO
      metadata={{
        title: content.seo.seoTitle,
        description: content.seo.seoDescription,
      }}
    />
    <Box
      as="section"
      bg="white"
      p={[6, 7, 9]}
      py={[8, 9]}
      maxWidth="700px"
      mx="auto"
    >
      <H1 mb={3}>{content.seo.seoTitle}</H1>
      <Text content={content._rawText} />
      <Button onClick={() => navigate('/')}>
        {content.cta.label}
        {content.cta.showArrow && <Button.Arrow />}
      </Button>
    </Box>
  </Layout>
)

export const query = graphql`
  query NotfoundPage {
    content: sanityNotFoundPageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      heading
      _rawText
      cta {
        label
        showArrow
      }
    }
  }
`

export default NotFoundPage
